export const columns = [
  {
    title: '轮播名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'imageUrl'}
  },

  {
    title: '地址',
    dataIndex: 'address',
    key: 'address',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '位置',
    dataIndex: 'position',
    key: 'position',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '状态',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'flagEnable'}
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:200,
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'createTime'}
  },
]
