<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit"
           okText="提交" :maskClosable="false">
    <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}"
                  :wrapper-col="{span:21}">

      <a-form-model-item label="轮播名称" prop="(name">
        <a-input v-model="rowData.name" placeholder="轮播名称"></a-input>
      </a-form-model-item>
      <!-- <a-form-model-item label="位置" prop="(positionId">
        <a-select placeholder="位置" style="width: 20%;" v-model="rowData.positionId">
          <a-select-option :value="item.id" v-for="(item, index) in positionList" :key="index">{{ item.title }}
          </a-select-option>
        </a-select> -->
      </a-form-model-item>
      <a-form-model-item label="地址" prop="(address">
        <a-input v-model="rowData.address" placeholder="地址"></a-input>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="(sort">
        <a-input v-model="rowData.sort" placeholder="排序"></a-input>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="(flagEnable">
        <a-radio-group v-model="rowData.flagEnable">
          <a-radio :value="false"> 禁用</a-radio>
          <a-radio :value="true"> 启用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="图片" prop="(imageUrl">
        <QiniuUpload type="image" :value.sync="rowData.imageUrl"></QiniuUpload>
        图片尺寸：630×360px 或等比例
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editMarketCarouselImage,
  selectByIdMarketCarouselImage,
  addMarketCarouselImage
} from '../api/MarketCarouselImageApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      positionList: [],
      type: 0,
      // 表单验证
      formRule: {
        id: [
          {required: true, message: '请输入主键ID', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入轮播名称', trigger: 'blur'}
        ],
        positionId: [
          {required: true, message: '请输入位置-存的是字典值的ID', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ],
        sort: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ],
        flagEnable: [
          {required: true, message: '请输入启用状态', trigger: 'blur'}
        ],
        imageUrl: [
          {required: true, message: '请输入图片', trigger: 'blur'}
        ],
        createUser: [
          {required: true, message: '请输入创建人', trigger: 'blur'}
        ],
        createUserId: [
          {required: true, message: '请输入创建人id', trigger: 'blur'}
        ],
        createTime: [
          {required: true, message: '请输入创建时间', trigger: 'blur'}
        ],
        modifyUser: [
          {required: true, message: '请输入修改人', trigger: 'blur'}
        ],
        modifyUserId: [
          {required: true, message: '请输入修改人id', trigger: 'blur'}
        ],
        modifyTime: [
          {required: true, message: '请输入修改时间', trigger: 'blur'}
        ],
        flagDel: [
          {required: true, message: '请输入是否删除', trigger: 'blur'}
        ],
        version: [
          {required: true, message: '请输入版本号', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle, type) {
      this.handle = handle
      this.visible = true
      this.rowData = row
      this.type = type
      if (handle === 'add') {
        this.$set(this.rowData, 'flagEnable', true)
        this.$set(this.rowData, 'type', type)
      }
      this.getList();
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        const res = this.handle === 'add' ? await addMarketCarouselImage(this.rowData) : await editMarketCarouselImage(this.rowData)
        if (res.code === 200) {
          this.$notification.success({message: res.message})
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({message: res.message})
        }
      })
    },
    getList() {
      this.axios.get('/api/base/system/dictionary/selectByCode/轮播图位置').then(res => { // 轮播图位置
        this.positionList = res.body
      })
    }
  },
  created() {

  },

}
</script>

<style lang="less" scoped>

</style>
